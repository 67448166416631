import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import {
  DocumentFieldCatalogApi,
  DocumentSubjectCatalogApi,
} from "src/api/catalog-api";
import ContentApi from "src/api/content-api";
import ENV from "src/config/ENV";
import LoadingSpinner from "src/components/loading-spinner";
import { useParams } from "react-router-dom";
import UserProfileApi from "src/api/user-profile-api";
import { Divider, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Pagination from "@mui/material/Pagination";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import UserContentTag from "../tag";
import Filter from "./components/filter";
import KeywordApi from "src/api/keyword-api";
import { useSelector } from "react-redux";
import { selectedAccount } from "src/store/slices/accountSlice";
import moment from "moment";

function ContentList() {
  const [data, setData] = useState(null);
  const [subjectCatalogs, setSubjectCatalogs] = useState([]);
  const [fieldCatalogs, setFieldCatalogs] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const [currentSubject, setCurrentSubject] = useState(null);
  const [currentField, setCurrentField] = useState(null);
  const [loadFullSubject, setLoadFullSubject] = useState(false);
  const [loadFullField, setLoadFullField] = useState(false);
  const [tags, setTags] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [subjectIds, setSubjectIds] = useState([]);
  const [fieldIds, setFieldIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [keywords, setKeywords] = useState(null);
  let { subjectId, fieldId } = useParams();

  const currentUser = useSelector(selectedAccount);

  useEffect(() => {
    if (subjectId && subjectId !== "0") {
      subjectIds.push(parseInt(subjectId, 10));
      setSubjectIds([...subjectIds]);
    }

    if (fieldId && fieldId !== "0") {
      fieldIds.push(parseInt(fieldId, 10));
      setFieldIds([...fieldIds]);
    }
  }, [subjectId, fieldId]);

  const pageChanged = (e, page) => {
    setPage(page);
  };

  useEffect(() => {
    setLoading(true);
    var tagNames = tags.map((item) => item.tag);
    new ContentApi()
      .searches2({
        Keyword: keyword,
        PageNumber: page,
        PageSize: 12,
        Tags: tagNames,
        SubjectIds: subjectIds,
        FieldIds: fieldIds,
      })
      .then((response) => {
        setData(response.data);
        setTotalPages(Math.ceil(response.data.totalItems / 20));
        setLoading(false);
        window.scrollTo(0, 0);
      });
  }, [page, fieldIds, subjectIds, tags, keyword]);

  useEffect(() => {
    DocumentSubjectCatalogApi.searches(null).then((response) => {
      setSubjectCatalogs(response.data.data);
    });

    DocumentFieldCatalogApi.searches(null).then((response) => {
      setFieldCatalogs(response.data.data);
    });

    new KeywordApi().searches().then((response) => {
      setKeywords(response.data.data);
    });
  }, []);

  useEffect(() => {
    if (!loadFullField) {
      window.scrollTo(0, 0);
    }
  }, [loadFullField]);

  useEffect(() => {
    if (!loadFullSubject) {
      window.scrollTo(0, 0);
    }
  }, [loadFullSubject]);

  const toggleSubjectId = (subjectId) => {
    var index = subjectIds.indexOf(subjectId);
    if (index > -1) {
      subjectIds.splice(index, 1);
    } else {
      subjectIds.push(subjectId);
    }
    setSubjectIds([...subjectIds]);
  };

  const toggleFieldId = (fieldId) => {
    var index = fieldIds.indexOf(fieldId);
    if (index > -1) {
      fieldIds.splice(index, 1);
    } else {
      fieldIds.push(fieldId);
    }
    setFieldIds([...fieldIds]);
  };

  const onSearch = (query) => {
    // setKeyword(query.Keyword);
    setLoading(true);
    new ContentApi().searches2(query).then((response) => {
      setData(response.data);
      setTotalPages(Math.ceil(response.data.totalItems / 20));
      setLoading(false);
      setShowFilter(false);
    });
  };

  return (
    <>
      <div className="container">
        <nav aria-label="breadcrumb" className="">
          <ol className="breadcrumb bc-edit my-4">
            <li className="breadcrumb-item">
              <Link to="/" className="icon-home">
                <img src="/theme/img/icon-home.svg" />
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Danh mục Tra cứu
            </li>
          </ol>
        </nav>
      </div>
      <div className="main-search pt-0" id="over_map">
        <div className="container">
          <div className="ms-search">
            <div className="ms-search-fillter dropdown">
              <div className="form-search-edit">
                <input
                  type="text"
                  name=""
                  placeholder="Nhập từ khóa"
                  onChange={(e) => setKeyword(e.target.value)}
                />
                <img className="icon-search" src="/theme/img/icon-search.svg" />
              </div>
              <a
                href="javascript:;"
                onClick={() => setShowFilter(!showFilter)}
                className="icon-fillter dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                <img src="/theme/img/icon-fillter.svg" />
              </a>
            </div>
            {/* {keywords && (
              <div className="search-tag">
                <label>Phổ biến:</label>
                <ul>
                  {keywords.map((item) => (
                    <li>
                      <Link
                        to={`/content?keyword=${encodeURIComponent(item.name)}`}
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )} */}
            <br />
          </div>
        </div>
      </div>
      <div className="container">
        <div
          className="main-news"
          style={
            {
              // boxShadow: "0px 4px 16px #0000001f",
              // padding: 24,
              // borderRadius: 12,
            }
          }
        >
          <div className="row">
            <div className="col-md-4">
              <div className="fillter-title">
                <label>Lọc theo</label>
                <a
                  onClick={() => {
                    setFieldIds([]);
                    setSubjectIds([]);
                  }}
                  style={{ color: "#9F2BD4", cursor: "pointer" }}
                >
                  Bỏ lọc
                </a>
              </div>
              <ul className="fillter-list">
                {subjectCatalogs &&
                  subjectCatalogs
                    .filter((x) => subjectIds.indexOf(x.id) >= 0)
                    .map((item) => (
                      <li onClick={() => toggleSubjectId(item.id)}>
                        <span>
                          {item.name}
                          <a style={{ cursor: "pointer" }}>
                            <img src="/theme/img/icon-close.svg" />
                          </a>
                        </span>
                      </li>
                    ))}
                {fieldCatalogs &&
                  fieldCatalogs
                    .filter((x) => fieldIds.indexOf(x.id) >= 0)
                    .map((item) => (
                      <li onClick={() => toggleFieldId(item.id)}>
                        <span>
                          {item.name}
                          <a style={{ cursor: "pointer" }}>
                            <img src="/theme/img/icon-close.svg" />
                          </a>
                        </span>
                      </li>
                    ))}
              </ul>
              <div className="box-fillter">
                <label className="bf-title">Lĩnh vực</label>
                {fieldCatalogs && (
                  <ul>
                    {fieldCatalogs
                      .slice(0, loadFullField ? fieldCatalogs.length : 6)
                      .map((item) => (
                        <>
                          <li>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name={`flexCheckDefault-field-${item.id}`}
                                id={`flexCheckDefault-field-${item.id}`}
                                onChange={() => toggleFieldId(item.id)}
                                checked={fieldIds.indexOf(item.id) > -1}
                              />
                              <label
                                className="form-check-label"
                                for={`flexCheckDefault-field-${item.id}`}
                              >
                                {item.name}
                              </label>
                            </div>
                          </li>
                        </>
                      ))}
                  </ul>
                )}
                <a
                  onClick={() => setLoadFullField(!loadFullField)}
                  style={{ color: "#9F2BD4", cursor: "pointer" }}
                >
                  {loadFullField && (
                    <>
                      Thu gọn{" "}
                      <img
                        style={{ transform: "rotate(360deg)" }}
                        src="/theme/img/icon-arowup.svg"
                      />
                    </>
                  )}
                  {!loadFullField && (
                    <>
                      Xem thêm <img src="/theme/img/icon-arowup.svg" />
                    </>
                  )}
                </a>
              </div>
              <div className="box-fillter">
                <label className="bf-title">Chủ đề</label>
                {subjectCatalogs && (
                  <ul>
                    {subjectCatalogs
                      .slice(0, loadFullSubject ? subjectCatalogs.length : 6)
                      .map((item) => (
                        <>
                          <li>
                            <div className="form-check">
                              <input
                                id={`flexCheckDefault-subject-${item.id}`}
                                name={`flexCheckDefault-${item.id}`}
                                className="form-check-input"
                                type="checkbox"
                                checked={subjectIds.indexOf(item.id) > -1}
                                onChange={() => toggleSubjectId(item.id)}
                              />
                              <label
                                className="form-check-label"
                                for={`flexCheckDefault-subject-${item.id}`}
                              >
                                {item.name}
                              </label>
                            </div>
                          </li>
                        </>
                      ))}
                  </ul>
                )}
                <a
                  onClick={() => setLoadFullSubject(!loadFullSubject)}
                  style={{ color: "#9F2BD4", cursor: "pointer" }}
                >
                  {loadFullSubject && (
                    <>
                      Thu gọn{" "}
                      <img
                        style={{ transform: "rotate(360deg)" }}
                        src="/theme/img/icon-arowup.svg"
                      />
                    </>
                  )}
                  {!loadFullSubject && (
                    <>
                      Xem thêm <img src="/theme/img/icon-arowup.svg" />
                    </>
                  )}
                </a>
              </div>
            </div>
            <div className="col-md-8">
              <div className="list-news-all">
                <LoadingSpinner active={loading}>
                  <>
                    {data &&
                      data.documentContents.map((item) => (
                        <Link
                          to={`/content/${item.id}`}
                          className="list-news-detail ln-detail"
                        >
                          <span>
                            <img
                              style={{
                                borderRadius: 10,
                              }}
                              src={
                                item.thumbnailUrl != ""
                                  ? `${ENV.baseFileImageUrl}${item.thumbnailUrl}`
                                  : "/theme/img/no-img-1.jpg"
                              }
                            />
                          </span>
                          <article>
                            <label style={{ cursor: "pointer" }}>
                              {item.code} {item.name}
                            </label>
                            <div className="valid-status-des">
                              {item.validStatus != 0 && (
                                <span className="bpt-status bpt-status-warning">
                                  {item.validStatusName}
                                  {item.validFromDate
                                    ? " từ ngày " + moment(item.validFromDate).format(
                                      "DD/MM/YYYY"
                                    )
                                    : ""}
                                </span>
                              )}
                              {item.validStatus == 0 && (
                                <span className="bpt-status bpt-status-success">
                                  Có hiệu lực
                                  {item.validFromDate
                                    ? " từ ngày " + moment(item.validFromDate).format(
                                      "DD/MM/YYYY"
                                    )
                                    : ""}
                                </span>
                              )}
                            </div>
                            <p className="lnd-tag color-9B9B9B">
                              {item.subjectCatalogName}
                              {item.subjectCatalogName &&
                                item.fieldCatalogName && <span></span>}
                              {item.fieldCatalogName}
                            </p>
                            <p className="lnd-text">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item.shortContent,
                                }}
                              />
                            </p>
                            <ul className="lnd-view">
                              {/* <li>
                                <img src="/theme/img/icon-news.svg" />
                                {item.totalRelatedDocumentContents}
                              </li>
                              <li>
                                <img src="/theme/img/icon-page.svg" />
                                {item.totalRelatedDocuments}
                              </li> */}
                              <li>
                                <img src="/theme/img/icon-comment2.svg" />
                                {item.totalComments}
                              </li>
                            </ul>
                          </article>
                        </Link>
                      ))}
                    <Pagination
                      color="secondary"
                      onChange={pageChanged}
                      count={totalPages}
                      variant="outlined"
                    />
                  </>
                </LoadingSpinner>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Filter
        onClose={() => setShowFilter(false)}
        onSearch={onSearch}
        open={showFilter}
      />
    </>
  );
}

export default ContentList;
