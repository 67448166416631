import OwlCarousel from "react-owl-carousel";
import LoadingSpinner from "src/components/loading-spinner";
import ENV from "src/config/ENV";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { saveSetting } from "src/store/slices/moduleSlice";
import moment from "moment";

function InterestDocumentContents({ loading, data }) {
  const [modules, setModules] = useCookies(["modules"]);
  const [isActive, setIsActive] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const moveTop = (module) => {
    let currentConfig = modules.modules;
    const index = currentConfig.indexOf(module);
    currentConfig.splice(index, 1);
    currentConfig[0] = module;
    setModules("modules", JSON.stringify(currentConfig));
    dispatch(saveSetting(currentConfig));
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (modules.modules && modules.modules[0] == "interest-document-contents") {
      setIsActive(true);
    }
  }, [modules]);

  return (
    <>
      <LoadingSpinner active={loading}>
        <div
          className="main-situation"
          style={{ backgroundColor: "white", paddingTop: isActive ? 20 : 60 }}
        >
          <div className="container">
            {modules.modules &&
              modules.modules[0] != "interest-document-contents" && (
                <a
                  style={{ cursor: "pointer" }}
                  className="d-block mb-3 upto"
                  onClick={() => moveTop("interest-document-contents")}
                >
                  <img src="/theme/img/icon-upto.svg" />
                </a>
              )}
            <div className="main-title text-center">
              <h2>Có thể bạn quan tâm</h2>
            </div>
            <div>
              {data && (
                <OwlCarousel
                  className="owl-carousel owl-theme situation-carousel"
                  loop
                  margin={10}
                  responsive={{
                    0: {
                      items: 1,
                    },
                    600: {
                      items: 3,
                    },
                  }}
                  nav
                >
                  {data.map((item) => (
                    <Link
                      to={`/content/${item.id}`}
                      key={`slide-suggest-${item.id}`}
                      className="ln-detail"
                    >
                      <span>
                        <img
                          style={{
                            borderRadius: 10,
                          }}
                          src={
                            item.thumbnailUrl != ""
                              ? `${ENV.baseFileImageUrl}${item.thumbnailUrl}`
                              : "/theme/img/no-img-1.jpg"
                          }
                        />
                      </span>
                      <article>
                        <label>{item.name}</label>
                        <div className="valid-status-des">
                          {item.validStatus != 0 && (
                            <span className="bpt-status bpt-status-warning">
                              {item.validStatusName}
                              {item.validFromDate
                                ? " từ ngày " + moment(item.validFromDate).format(
                                  "DD/MM/YYYY"
                                )
                                : ""}
                            </span>
                          )}
                          {item.validStatus == 0 && (
                            <span className="bpt-status bpt-status-success">
                              Có hiệu lực
                              {item.validFromDate
                                ? " từ ngày " + moment(item.validFromDate).format(
                                  "DD/MM/YYYY"
                                )
                                : ""}
                            </span>
                          )}
                        </div>
                        <p className="lnd-tag color-9B9B9B">
                          {item.fieldCatalogName}
                          {item.fieldCatalogName && item.fieldCatalogName && (
                            <span></span>
                          )}
                          {item.subjectCatalogName}
                        </p>
                        <ul className="lnd-view">
                          {/* <li>
                            <img src="/theme/img/icon-news.svg" />
                            {item.totalRelatedDocumentContents}
                          </li>
                          <li>
                            <img src="/theme/img/icon-page.svg" />
                            {item.totalRelatedDocuments}
                          </li> */}
                          <li>
                            <img src="/theme/img/icon-comment2.svg" />
                            {item.totalComments}
                          </li>
                          {/* <li>
                            {item.validStatus != 0 && (
                              <span className="bpt-status bpt-status-warning">
                                {item.validStatusName}
                              </span>
                            )}
                            {item.validStatus == 0 && (
                              <span className="bpt-status bpt-status-success">
                                {item.validStatusName}
                              </span>
                            )}
                          </li> */}
                        </ul>
                      </article>
                    </Link>
                  ))}
                </OwlCarousel>
              )}
            </div>
          </div>
        </div>
      </LoadingSpinner>
    </>
  );
}

export default InterestDocumentContents;
