import React, { useEffect, useState } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ENV from "src/config/ENV";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import DocumentDetailApi from "src/api/document-detail-api";
import { selectedAccount } from "src/store/slices/accountSlice";
import UserProfileApi from "src/api/user-profile-api";
import LoadingSpinner from "src/components/loading-spinner";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import moment from "moment";
import NewsApi from "src/api/news-api";
import CommentApi from "src/api/comment-api";
import InteresetCatalogs from "src/components/interest-catalogs";
import {
  DocumentFieldCatalogApi,
  DocumentSubjectCatalogApi,
} from "src/api/catalog-api";
import Expert from "src/components/expert";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

function DocumentDetail() {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState(null);
  const [subjectCatalogs, setSubjectCatalogs] = useState([]);
  const [fieldCatalogs, setFieldCatalogs] = useState([]);

  const currentUser = useSelector(selectedAccount);
  const [data, setData] = useState(null);
  let { id } = useParams();

  useEffect(() => {
    if (!id) return;
    setLoading(true);

    new DocumentDetailApi().get(id).then((response) => {
      setData(response.data.value);
      setLoading(false);
    });
  }, [id]);

  useEffect(() => {
    if (currentUser && currentUser.id) {
      const api = new UserProfileApi();
      api.me().then((response) => {
        setProfile(response.data.value);
      });
    }
  }, [currentUser]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  useEffect(() => {
    if (profile && profile.notificationDocumentContentFromFields) {
      return;
    }

    DocumentSubjectCatalogApi.searches(null).then((response) => {
      setSubjectCatalogs(response.data.data);
    });

    DocumentFieldCatalogApi.searches(null).then((response) => {
      setFieldCatalogs(response.data.data);
    });
  }, [profile]);

  return (
    <>
      <div className="container">
        <nav aria-label="breadcrumb" className="">
          <ol className="breadcrumb bc-edit my-4">
            <li className="breadcrumb-item">
              <Link to="/" className="icon-home">
                <img src="/theme/img/icon-home.svg" />
              </Link>
            </li>
            {data && data.fieldCatalogName && data.fieldCatalogName != "" && (
              <li className="breadcrumb-item">
                {/* <Link to={`/contents/${data.fieldCatalogId}/0`}> */}
                {data.fieldCatalogName}
                {/* </Link> */}
              </li>
            )}
            {data &&
              data.subjectCatalogName &&
              data.subjectCatalogName != "" && (
                <li className="breadcrumb-item">
                  {/* <Link to={`/contents/0/${data.subjectCatalogId}`}> */}
                  {data.subjectCatalogName}
                  {/* </Link> */}
                </li>
              )}
          </ol>
        </nav>
      </div>
      <div className="main-news">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <LoadingSpinner active={loading}>
                {data && (
                  <>
                    <div className="box-posts">
                      <div className="bp-title bp-title-other">
                        <Link to="/contents">
                          <img src="/theme/img/icon-back.svg" />
                        </Link>
                        <article>
                          <h2>{data.name}</h2>
                        </article>
                      </div>
                      <div>
                        {data.validStatus != 0 && (
                          <span className="bpt-status bpt-status-warning">
                            {data.validStatusName}
                          </span>
                        )}
                        {data.validStatus == 0 && (
                          <span className="bpt-status bpt-status-success">
                            {data.validStatusName}
                          </span>
                        )}
                      </div>
                      <div className="bp-content">
                        {/* <label className="bpc-describe mt-4">
                          Nội dung
                        </label> */}
                        <p className="mt-0">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: data.content,
                            }}
                          />
                        </p>
                        <br />
                        {data.metaFields && (
                          <div className="">
                            {data.metaFields.map((item) => (
                              <Accordion>
                                <AccordionSummary
                                  expandIcon={<ArrowDownwardIcon />}
                                >
                                  <b>{item.name}</b>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <div
                                    className="mt-0"
                                    style={{
                                      textAlign: "justify",
                                      padding: 10,
                                    }}
                                  >
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: item.data,
                                      }}
                                    />
                                  </div>
                                </AccordionDetails>
                              </Accordion>
                            ))}
                          </div>
                        )}
                        {data.document && (
                          <>
                            <div className="box-news bn-comment-hot">
                              <label>Thông tin Văn bản gốc</label>
                              <br />
                              <div>
                                <Link to={`/document/${data.document.id}`}>
                                  {data.document.code}&nbsp;
                                  {data.document.shortContent}
                                </Link>
                              </div>
                              <br />
                              <Grid container spacing={3}>
                                <Grid item xs={6} sm={2}>
                                  <b>Tình trạng:</b>
                                </Grid>
                                <Grid item xs={6} sm={2}>
                                  {data.document.validStatus != 0 && (
                                    <span className="bpt-status bpt-status-warning">
                                      {data.document.validStatusName}
                                    </span>
                                  )}
                                  {data.document.validStatus == 0 && (
                                    <span className="bpt-status bpt-status-success">
                                      {data.document.validStatusName}
                                    </span>
                                  )}
                                </Grid>
                                <Grid item xs={6} sm={2}>
                                  <b>Ngày hiệu lực:</b>
                                </Grid>
                                <Grid item xs={6} sm={2}>
                                  {moment(data.document.validFromDate).format(
                                    "DD/MM/YYYY"
                                  )}
                                </Grid>
                                <Grid item xs={6} sm={2}>
                                  <b>Ngày xuất bản:</b>
                                </Grid>
                                <Grid item xs={6} sm={2}>
                                  {moment(data.document.publishDate).format(
                                    "DD/MM/YYYY"
                                  )}
                                </Grid>
                              </Grid>
                              {data.document.relatedDocumentDetails &&
                                data.document.relatedDocumentDetails.length >
                                0 && (
                                  <>
                                    <br />
                                    <label>Nội dung liên quan</label>
                                    <Table>
                                      <TableBody>
                                        {data.document.relatedDocumentDetails.map(
                                          (item, index) => (
                                            <TableRow>
                                              <TableCell>{index + 1}</TableCell>
                                              <TableCell>
                                                <a
                                                  href={`/documentdetail/${item.id}`}
                                                >
                                                  {item.name}
                                                </a>
                                              </TableCell>
                                              <TableCell>
                                                {item.validStatusName}
                                              </TableCell>
                                            </TableRow>
                                          )
                                        )}
                                      </TableBody>
                                    </Table>
                                  </>
                                )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>

                    {data.relatedDocuments &&
                      data.relatedDocuments.length > 0 && (
                        <div className="Post-document">
                          <div className="main-title mb-3">
                            <h3>Văn bản, Tài liệu liên quan</h3>
                          </div>
                          <OwlCarousel
                            className="owl-carousel owl-theme document-carousel"
                            items={2}
                            margin={10}
                            nav
                            responsive={{
                              0: {
                                items: 1,
                              },
                              600: {
                                items: 2,
                              },
                            }}
                          >
                            {data.relatedDocuments.map((item) => (
                              <Link
                                to={`/app/documents/view/${item.id}`}
                                className="dc-detail"
                              >
                                <span>
                                  <img src="/theme/img/icon-page-white.svg" />
                                </span>
                                <article>
                                  <label style={{ cursor: "pointer" }}>
                                    {item.shortContent}
                                  </label>
                                  {/* <p className="dcd-tag">(Nguồn gốc tài liệu)</p> */}
                                  <p className="dcd-id">
                                    Số hiệu: {item.code}
                                    {item.validStatus != 0 && (
                                      <span className="bpt-status bpt-status-warning">
                                        {item.validStatusName}
                                      </span>
                                    )}
                                    {item.validStatus == 0 && (
                                      <span className="bpt-status bpt-status-success">
                                        {item.validStatusName}
                                      </span>
                                    )}
                                    {/* <span className="bpt-status bpt-status-warning">
                                    {item.validStatusName}
                                  </span> */}
                                  </p>
                                </article>
                              </Link>
                            ))}
                          </OwlCarousel>
                        </div>
                      )}
                  </>
                )}
              </LoadingSpinner>
            </div>
            <div className="col-md-4">
              {data &&
                ((data.referenceDocumentDetails &&
                  data.referenceDocumentDetails.length > 0) ||
                  (data.relatedDocumentDetails &&
                    data.relatedDocumentDetails.length > 0)) && (
                  <div className="box-news bn-news-hot">
                    <label>Nội dung liên quan</label>
                    <ul>
                      {data.referenceDocumentDetails && data.referenceDocumentDetails.map((item) => (
                        <li>
                          <Link
                            to={`/documentdetail/${item.id}`}
                            className="ln-detail"
                          >
                            <article>
                              <label>{item.name}</label>
                              <div className="valid-status-des">
                                {item.validStatus != 0 && (
                                  <span className="bpt-status bpt-status-warning">
                                    {item.validStatusName}
                                    {item.validFromDate
                                      ? " từ ngày " + moment(item.validFromDate).format(
                                        "DD/MM/YYYY"
                                      )
                                      : ""}
                                  </span>
                                )}
                                {item.validStatus == 0 && (
                                  <span className="bpt-status bpt-status-success">
                                    Có hiệu lực
                                    {item.validFromDate
                                      ? " từ ngày " + moment(item.validFromDate).format(
                                        "DD/MM/YYYY"
                                      )
                                      : ""}
                                  </span>
                                )}
                              </div>
                            </article>
                          </Link>
                        </li>
                      ))}
                      {data.relatedDocumentDetails && data.relatedDocumentDetails.map((item) => (
                        <li>
                          <Link
                            to={`/documentdetail/${item.id}`}
                            className="ln-detail"
                          >
                            <article>
                              <label>{item.name}</label>
                              <div className="valid-status-des">
                                {item.validStatus != 0 && (
                                  <span className="bpt-status bpt-status-warning">
                                    {item.validStatusName}
                                    {item.validFromDate
                                      ? " từ ngày " + moment(item.validFromDate).format(
                                        "DD/MM/YYYY"
                                      )
                                      : ""}
                                  </span>
                                )}
                                {item.validStatus == 0 && (
                                  <span className="bpt-status bpt-status-success">
                                    Có hiệu lực
                                    {item.validFromDate
                                      ? " từ ngày " + moment(item.validFromDate).format(
                                        "DD/MM/YYYY"
                                      )
                                      : ""}
                                  </span>
                                )}
                              </div>
                            </article>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <Expert />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DocumentDetail;
